export const AddMemberButton = () => import('../../components/AddMemberButton.vue' /* webpackChunkName: "components/add-member-button" */).then(c => wrapFunctional(c.default || c))
export const AddMemberForm = () => import('../../components/AddMemberForm.vue' /* webpackChunkName: "components/add-member-form" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CancelToolbarMenu = () => import('../../components/CancelToolbarMenu.vue' /* webpackChunkName: "components/cancel-toolbar-menu" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CedulaModal = () => import('../../components/CedulaModal.vue' /* webpackChunkName: "components/cedula-modal" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactSlideOver = () => import('../../components/ContactSlideOver.vue' /* webpackChunkName: "components/contact-slide-over" */).then(c => wrapFunctional(c.default || c))
export const CreateRx = () => import('../../components/CreateRx.vue' /* webpackChunkName: "components/create-rx" */).then(c => wrapFunctional(c.default || c))
export const DesktopMenu = () => import('../../components/DesktopMenu.vue' /* webpackChunkName: "components/desktop-menu" */).then(c => wrapFunctional(c.default || c))
export const DesktopMenuResponsiveWrapper = () => import('../../components/DesktopMenuResponsiveWrapper.vue' /* webpackChunkName: "components/desktop-menu-responsive-wrapper" */).then(c => wrapFunctional(c.default || c))
export const DiagnosisAutocomplete = () => import('../../components/DiagnosisAutocomplete.vue' /* webpackChunkName: "components/diagnosis-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const DotMenu = () => import('../../components/DotMenu.vue' /* webpackChunkName: "components/dot-menu" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const DropdownOption = () => import('../../components/DropdownOption.vue' /* webpackChunkName: "components/dropdown-option" */).then(c => wrapFunctional(c.default || c))
export const EditProfileButton = () => import('../../components/EditProfileButton.vue' /* webpackChunkName: "components/edit-profile-button" */).then(c => wrapFunctional(c.default || c))
export const Emojis = () => import('../../components/Emojis.vue' /* webpackChunkName: "components/emojis" */).then(c => wrapFunctional(c.default || c))
export const FavoritesCard = () => import('../../components/FavoritesCard.vue' /* webpackChunkName: "components/favorites-card" */).then(c => wrapFunctional(c.default || c))
export const FavoritesIconMenu = () => import('../../components/FavoritesIconMenu.vue' /* webpackChunkName: "components/favorites-icon-menu" */).then(c => wrapFunctional(c.default || c))
export const HospitalCard = () => import('../../components/HospitalCard.vue' /* webpackChunkName: "components/hospital-card" */).then(c => wrapFunctional(c.default || c))
export const HospitalForm = () => import('../../components/HospitalForm.vue' /* webpackChunkName: "components/hospital-form" */).then(c => wrapFunctional(c.default || c))
export const HospitalMembershipCard = () => import('../../components/HospitalMembershipCard.vue' /* webpackChunkName: "components/hospital-membership-card" */).then(c => wrapFunctional(c.default || c))
export const HospitalRedirect = () => import('../../components/HospitalRedirect.vue' /* webpackChunkName: "components/hospital-redirect" */).then(c => wrapFunctional(c.default || c))
export const Ian = () => import('../../components/Ian.vue' /* webpackChunkName: "components/ian" */).then(c => wrapFunctional(c.default || c))
export const IanInput = () => import('../../components/IanInput.vue' /* webpackChunkName: "components/ian-input" */).then(c => wrapFunctional(c.default || c))
export const IanOutput = () => import('../../components/IanOutput.vue' /* webpackChunkName: "components/ian-output" */).then(c => wrapFunctional(c.default || c))
export const InteractionsStream = () => import('../../components/InteractionsStream.vue' /* webpackChunkName: "components/interactions-stream" */).then(c => wrapFunctional(c.default || c))
export const IntlTelInput = () => import('../../components/IntlTelInput.vue' /* webpackChunkName: "components/intl-tel-input" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const MaterialIcon = () => import('../../components/MaterialIcon.vue' /* webpackChunkName: "components/material-icon" */).then(c => wrapFunctional(c.default || c))
export const MinimalLayout = () => import('../../components/MinimalLayout.vue' /* webpackChunkName: "components/minimal-layout" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileSearch = () => import('../../components/MobileSearch.vue' /* webpackChunkName: "components/mobile-search" */).then(c => wrapFunctional(c.default || c))
export const NavMenu = () => import('../../components/NavMenu.vue' /* webpackChunkName: "components/nav-menu" */).then(c => wrapFunctional(c.default || c))
export const NavMenuNewRx = () => import('../../components/NavMenuNewRx.vue' /* webpackChunkName: "components/nav-menu-new-rx" */).then(c => wrapFunctional(c.default || c))
export const NavMenuProfile = () => import('../../components/NavMenuProfile.vue' /* webpackChunkName: "components/nav-menu-profile" */).then(c => wrapFunctional(c.default || c))
export const NewFavoriteToolbarMenu = () => import('../../components/NewFavoriteToolbarMenu.vue' /* webpackChunkName: "components/new-favorite-toolbar-menu" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const Prescription = () => import('../../components/Prescription.vue' /* webpackChunkName: "components/prescription" */).then(c => wrapFunctional(c.default || c))
export const PrescriptionToolbarItems = () => import('../../components/PrescriptionToolbarItems.vue' /* webpackChunkName: "components/prescription-toolbar-items" */).then(c => wrapFunctional(c.default || c))
export const PrintOptions = () => import('../../components/PrintOptions.vue' /* webpackChunkName: "components/print-options" */).then(c => wrapFunctional(c.default || c))
export const ResendToolbarMenu = () => import('../../components/ResendToolbarMenu.vue' /* webpackChunkName: "components/resend-toolbar-menu" */).then(c => wrapFunctional(c.default || c))
export const Resource = () => import('../../components/Resource.vue' /* webpackChunkName: "components/resource" */).then(c => wrapFunctional(c.default || c))
export const RoleDropdown = () => import('../../components/RoleDropdown.vue' /* webpackChunkName: "components/role-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ShowTosButton = () => import('../../components/ShowTosButton.vue' /* webpackChunkName: "components/show-tos-button" */).then(c => wrapFunctional(c.default || c))
export const SignatureInput = () => import('../../components/SignatureInput.vue' /* webpackChunkName: "components/signature-input" */).then(c => wrapFunctional(c.default || c))
export const SignaturePad = () => import('../../components/SignaturePad.vue' /* webpackChunkName: "components/signature-pad" */).then(c => wrapFunctional(c.default || c))
export const SkipLink = () => import('../../components/SkipLink.vue' /* webpackChunkName: "components/skip-link" */).then(c => wrapFunctional(c.default || c))
export const SlideOver = () => import('../../components/SlideOver.vue' /* webpackChunkName: "components/slide-over" */).then(c => wrapFunctional(c.default || c))
export const SpecialtyAutocomplete = () => import('../../components/SpecialtyAutocomplete.vue' /* webpackChunkName: "components/specialty-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const Stamp = () => import('../../components/Stamp.vue' /* webpackChunkName: "components/stamp" */).then(c => wrapFunctional(c.default || c))
export const TextArea = () => import('../../components/TextArea.vue' /* webpackChunkName: "components/text-area" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../components/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHelpButton = () => import('../../components/ToolbarHelpButton.vue' /* webpackChunkName: "components/toolbar-help-button" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Oauth = () => import('../../components/oauth.vue' /* webpackChunkName: "components/oauth" */).then(c => wrapFunctional(c.default || c))
export const InputsDateOfBirth = () => import('../../components/Inputs/DateOfBirth.vue' /* webpackChunkName: "components/inputs-date-of-birth" */).then(c => wrapFunctional(c.default || c))
export const InputsText = () => import('../../components/Inputs/Text.vue' /* webpackChunkName: "components/inputs-text" */).then(c => wrapFunctional(c.default || c))
export const MedicationAutocomplete = () => import('../../components/Medication/Autocomplete.vue' /* webpackChunkName: "components/medication-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const MedicationCard = () => import('../../components/Medication/Card.vue' /* webpackChunkName: "components/medication-card" */).then(c => wrapFunctional(c.default || c))
export const MedicationImage = () => import('../../components/Medication/Image.vue' /* webpackChunkName: "components/medication-image" */).then(c => wrapFunctional(c.default || c))
export const PatientAutocomplete = () => import('../../components/Patient/Autocomplete.vue' /* webpackChunkName: "components/patient-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const PatientCard = () => import('../../components/Patient/Card.vue' /* webpackChunkName: "components/patient-card" */).then(c => wrapFunctional(c.default || c))
export const PatientCreate = () => import('../../components/Patient/Create.vue' /* webpackChunkName: "components/patient-create" */).then(c => wrapFunctional(c.default || c))
export const ResourceCards = () => import('../../components/Resource/Cards.vue' /* webpackChunkName: "components/resource-cards" */).then(c => wrapFunctional(c.default || c))
export const ResourceDataTable = () => import('../../components/Resource/DataTable.vue' /* webpackChunkName: "components/resource-data-table" */).then(c => wrapFunctional(c.default || c))
export const ToursHistory = () => import('../../components/Tours/History.vue' /* webpackChunkName: "components/tours-history" */).then(c => wrapFunctional(c.default || c))
export const ToursWelcome = () => import('../../components/Tours/Welcome.vue' /* webpackChunkName: "components/tours-welcome" */).then(c => wrapFunctional(c.default || c))
export const WidgetsIan = () => import('../../components/Widgets/Ian.vue' /* webpackChunkName: "components/widgets-ian" */).then(c => wrapFunctional(c.default || c))
export const WidgetsLastMonth = () => import('../../components/Widgets/LastMonth.vue' /* webpackChunkName: "components/widgets-last-month" */).then(c => wrapFunctional(c.default || c))
export const WidgetsLastMonthGraph = () => import('../../components/Widgets/LastMonthGraph.vue' /* webpackChunkName: "components/widgets-last-month-graph" */).then(c => wrapFunctional(c.default || c))
export const WidgetsLatestMedications = () => import('../../components/Widgets/LatestMedications.vue' /* webpackChunkName: "components/widgets-latest-medications" */).then(c => wrapFunctional(c.default || c))
export const WidgetsNews = () => import('../../components/Widgets/News.vue' /* webpackChunkName: "components/widgets-news" */).then(c => wrapFunctional(c.default || c))
export const WidgetsReview = () => import('../../components/Widgets/Review.vue' /* webpackChunkName: "components/widgets-review" */).then(c => wrapFunctional(c.default || c))
export const WidgetsTopMedications = () => import('../../components/Widgets/TopMedications.vue' /* webpackChunkName: "components/widgets-top-medications" */).then(c => wrapFunctional(c.default || c))
export const WidgetsTopMedicationsGraph = () => import('../../components/Widgets/TopMedicationsGraph.vue' /* webpackChunkName: "components/widgets-top-medications-graph" */).then(c => wrapFunctional(c.default || c))
export const WidgetsWeather = () => import('../../components/Widgets/Weather.vue' /* webpackChunkName: "components/widgets-weather" */).then(c => wrapFunctional(c.default || c))
export const SocialFacebook = () => import('../../components/social/facebook.vue' /* webpackChunkName: "components/social-facebook" */).then(c => wrapFunctional(c.default || c))
export const SocialGoogle = () => import('../../components/social/google.vue' /* webpackChunkName: "components/social-google" */).then(c => wrapFunctional(c.default || c))
export const SocialTwitter = () => import('../../components/social/twitter.vue' /* webpackChunkName: "components/social-twitter" */).then(c => wrapFunctional(c.default || c))
export const WidgetsPaymentActivation = () => import('../../components/Widgets/Payment/Activation.vue' /* webpackChunkName: "components/widgets-payment-activation" */).then(c => wrapFunctional(c.default || c))
export const WidgetsPaymentMini = () => import('../../components/Widgets/Payment/Mini.vue' /* webpackChunkName: "components/widgets-payment-mini" */).then(c => wrapFunctional(c.default || c))
export const WidgetsPaymentOverlay = () => import('../../components/Widgets/Payment/Overlay.vue' /* webpackChunkName: "components/widgets-payment-overlay" */).then(c => wrapFunctional(c.default || c))
export const WidgetsPaymentPending = () => import('../../components/Widgets/Payment/Pending.vue' /* webpackChunkName: "components/widgets-payment-pending" */).then(c => wrapFunctional(c.default || c))
export const WidgetsPayment = () => import('../../components/Widgets/Payment/index.vue' /* webpackChunkName: "components/widgets-payment" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
